















































import { BootstrapButton, BootstrapLink } from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { JobOffer } from '../../../../../models'
import { Responsive } from '../../../../../extensions'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TableRow>({
  name: 'TableRow',
  components: { BootstrapButton, BootstrapLink }
})
export class TableRow extends Mixins<Responsive>(Responsive) {
  /**
   * @see JobOffer.departments
   */
  @Prop({ type: Array, required: false })
  public readonly departments?: JobOffer['departments']

  /**
   * @see JobOffer.cities
   */
  @Prop({ type: String, required: false })
  public readonly city?: JobOffer['city']

  /**
   * @see JobOffer.title
   */
  @Prop({ type: String, required: true })
  public readonly title!: JobOffer['title']

  /**
   * @see JobOffer.url
   */
  @Prop({ type: String, required: true })
  public readonly url!: JobOffer['url']

  /**
   * @see JobOffer.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: true })
  public readonly openLinkInNewTab!: JobOffer['openLinkInNewTab']

  /**
   * Determines whether the component has been provided with the correct `city` @Prop.
   */
  public get hasCity (): boolean {
    return typeof this.city !== 'undefined' && this.city.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `departments` @Prop.
   */
  public get hasDepartments (): boolean {
    return typeof this.departments !== 'undefined' &&
      Array.isArray(this.departments) &&
      this.departments.length > 0
  }

  /**
   * Determines whether links will be open in new tab.
   */
  public get newTab (): boolean {
    return !this.isMobile && !!this.openLinkInNewTab
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasTitle && this.hasUrl
  }

  /**
   * Determines whether the component has been provided with the correct `title` @Prop.
   */
  private get hasTitle (): boolean {
    return typeof this.title === 'string' && this.title.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `url` @Prop.
   */
  private get hasUrl (): boolean {
    return typeof this.url === 'string' && this.url.length > 0
  }
}

export default TableRow
