






























import { Component, Mixins } from 'vue-property-decorator'

import { Responsive } from '../../../../../extensions'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TableHeader>({ name: 'TableHeader' })
export class TableHeader extends Mixins<Responsive>(Responsive) {}

export default TableHeader
